<template>
  <main>
    <div class="holder">
      <back-button/>

      <section class="form full">
        <h1>{{ isNew ? $t('add-item.add-item') : $t('add-item.edit-item')}}</h1>

        <form>
          <div class="section" id="upload-files">
            <h2>{{ $t('add-item.choose-photo') }}</h2>

            <div class="field" id="upload-files-field">
              <p>{{ $t('add-item.drag-here') + " " + $t('add-item.or') + " " }}<a href="#" class="js-upload-file">{{ $t('add-item.choose-on-pc') }}</a></p>
              <form ref="fileInput" enctype="multipart/form-data">
                <input type="file" name="photo" id="upload-files-input" multiple accept="image/*" @change="uploadPhotoViaExplorer">
              </form>
              <ul>
                <li v-for="(item, index) in files" :key="index" v-if="item.id !== 'del'" style="display: grid;">
                  <div v-if="item.id === 'load'" style="justify-self: center;align-self: center;">
                    <loading-progress
                        :progress="0"
                        :indeterminate="true"
                        :counter-clockwise="false"
                        :hide-background="false"
                        shape="circle"
                        :size="loadingIconSize"
                    />
                  </div>
                  <div v-else>
                    <img :src="createResourceUrl(item.id)" alt="">
                    <span class="close" @click="removePhoto(index)"></span>
                  </div>
                </li>
                <li>
                  <a ref="addImg" class="js-upload-file">
                    <span>{{ $t('add-item.add-photo') }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="section" id="location">
            <h2>{{ $t('add-item.choose-location') }}</h2>
            <div id="map" class="field" v-if="goods.geo.latitude">
              <GmapMap :center="{ lat: goods.geo.latitude, lng: goods.geo.longitude }"
                       :zoom="15"
                       map-type-id="terrain"
                       style="width: 100%; height: 100%"
                       ref="mapRef"
                       :options="{
                         zoomControl: false,
                         mapTypeControl: false,
                         scaleControl: false,
                         streetViewControl: false,
                         rotateControl: false,
                         fullscreenControl: false,
                         disableDefaultUi: false
                       }"
                       @click="onMapClicked"
              >
                <GmapMarker v-if="!!(google)"
                            :position="{ lat: goods.geo.latitude, lng: goods.geo.longitude }"
                            :draggable="false"
                            :icon="{
                              strokeColor: '#FFFFFF',
                              strokeOpacity: 1,
                              strokeWeight: 3,
                              fillColor: '#00D9B2',
                              fillOpacity: 1,
                              path: google.maps.SymbolPath.CIRCLE,
                              scale: 15,
                              anchor: new google.maps.Point(0, 0)
                            }"
                >
                </GmapMarker>
              </GmapMap>
            </div>
            <div class="field" v-else @click="onMapClicked"><span>{{ $t('add-item.add-location') }}</span></div>
          </div>

          <div class="section" id="category">
            <h2>{{ $t('add-item.choose-category') }}</h2>

            <ul>
              <li class="toys" v-for="item in goodsCategoriesForCreation" :key="item.id" @click="changeCategory(item)">
                <input type="radio"
                       class="ui-checkboxradio ui-helper-hidden-accessible">
                <label class="ui-checkboxradio-label ui-corner-all ui-button ui-widget ui-checkboxradio-radio-label"
                       :class="{'ui-checkboxradio-checked ui-state-active': item.id === selected.category}">
                  <span>
                    <img :src="createResourceUrl(item.resourceId)" class="svg"
                         :class="{'hollow' : item.id == selected.category}">
                  </span>
                  {{ item.name }}
                </label>
              </li>
            </ul>
          </div>

          <p v-if="note != null && note.length > 0">{{ note }}</p>

          <div class="section" id="add-item-name">
            <h2>{{ $t('add-item.add-name') }}</h2>
            <input type="text" name="title" :placeholder="$t('common.enter')" maxlength="255" v-model="goods.title">
          </div>

          <div class="section">
            <h2>{{ $t('add-item.add-description') }}</h2>
            <textarea name="description" :placeholder="$t('common.enter')" class="js-autoheight"
                      v-model="goods.description"></textarea>
          </div>

          <choose-duration :its-for="'goods'" @onChangeExpireDate="setExpireDate"/>

          <button class="btn" type="button" @click="onSaveGoodsClicked">{{ isNew ? $t('add-item.publish') : $t('add-item.save') }}</button>
        </form>
      </section>
    </div>
  </main>
</template>

<script>
  import uploadFiles from '../assets/js/init/uploadFiles';
  import modal from '../assets/js/init/modal';
  import { mapActions, mapGetters } from 'vuex';
  import BackButton from '../components/layouts/BackButton.vue';
  import ChooseDuration from '../components/ChooseDuration.vue';
  import messages from '../assets/js/messages';
  import { gmapApi } from 'gmap-vue';
  import { eventBus } from '../main';

  export default {
    name: 'AddItem',
    title: 'Add Item',
    components: {
      ChooseDuration,
      BackButton,
    },
    data() {
      return {
        isNew: true,
        selected: {
          category: null,
        },
        goods: {
          id: null,
          title: null,
          description: null,
          geo: {
            latitude: null,
            longitude: null,
          },
          category: null,
          favoriteResourceId: null,
          resourceIds: [],
          expireDate: null,
          favorite: null,
        },
        loadingIconSize: null,
        files: [],
        note: null
      };
    },
    computed: {
      ...mapGetters(['goodsCategoriesForCreation']),
      google: gmapApi,
    },
    // нельзя просто взять и перейти на страницу редактирования напрямую
    beforeRouteEnter(to, from, next) {
      if((to.params.id) && !(to.params.goods)) {
       next({
          name: 'goods',
          params: { id: to.params.id},
          replace: true
        });
      } else next();
    },
    created() {
      if(this.$route.params.goods){
        let date = this.$route.params.goods.expireDate;
        if (date !== undefined && date !== null) {
          let currentDate = new Date();
          let expDate = new Date(date)
          if(currentDate < expDate) {
            this.anotherValue = this.$route.params.goods.expireDate;
          }
        }
        this.isNew = false;
        this.editMapper();
      }
      this.loadGoodsCategoriesForCreation();
    },
    mounted() {
      this.loaderIconInit();
      this.init();
      uploadFiles.init(this.uploadPhotoViaDrop);

      eventBus.$on('applyMarker', (markers) => {
        if(markers.length) {
          this.goods.geo.latitude = markers[0].geo.latitude
          this.goods.geo.longitude = markers[0].geo.longitude
        }
      });
    },
    methods: {
      ...mapActions(['loadGoodsCategoriesForCreation', 'saveGoods', 'editGoods', 'uploadFile']),
      init() {
        $('textarea.js-autoheight').each(function(i, textarea) {
          if (!$(textarea).data('autoheight-inited')) {
            $(textarea).attr('rows', 1);
            $(textarea).on('input', function() {
              $(this).css('height', 'auto');
              if ($(this)[0].scrollHeight > 0) $(this).css('height', $(this)[0].scrollHeight+'px');
            });
            if ($(textarea).css('display') != 'none') $(textarea).trigger('input');
            $(textarea).data('autoheight-inited', true);
          }
        });
      },
      loaderIconInit() {
        let that = this;
        this.loadingIconSize = this.$refs.addImg.clientHeight / 2;
        window.addEventListener('resize', function () {
          that.loadingIconSize = that.$refs.addImg.clientHeight / 2;
        });
      },
      uploadPhotoViaExplorer(event){
        let files = [];
        files.push(...event.target.files);
        this.uploadPhoto(files);
      },
      uploadPhotoViaDrop(files) {
        this.uploadPhoto(files)
      },
      uploadPhoto(files) {
        this.$refs.fileInput.reset();
        for (let i = 0; i < files.length; i++) {
          let newFile = {
            id: 'load'
          };
          this.files.push(newFile);
          let formData = new FormData();
          formData.append('file', files[i]);
          this.uploadFile(formData)
            .then((response) => {
              newFile.id = response.data.id;
            })
            .catch(() => {
              newFile.id = 'del';
              this.findAndDeleteBadPhoto();
              this.showToast(this.$t('toast.one-image-error'), 'error');
            });
        }
      },
      removePhoto(index) {
        this.files.splice(index, 1);
      },
      changeCategory(item) {
        this.selected.category = item.id;
        this.goods.category = item.id;
        this.note = item.note;
      },
      setExpireDate(value) {
        this.goods.expireDate = value;
      },
      onSaveGoodsClicked() {
        /*let $input = $('#add-item-name');
        messages.msgSetError($input.find('input'), 'Обязательно к заполнению');
        $input.find('input')
          .addClass('err');*/
        if (!this.goods.category && (this.goods.title !== '' || this.goods.title !== null)
          && (this.goods.description !== '' || this.goods.description !== null)) {
          this.showToast(this.$t('toast.not-all-fields'), 'warning');
          return;
        }
        for (let i = 0; i < this.files.length; i++) {
          if (this.files[i].id === 'load') {
            this.showToast(this.$t('toast.wait-for-images'), 'warning');
            return;
          }
        }
        this.findAndDeleteBadPhoto();
        if (this.files.length > 0) {
          this.goods.favoriteResourceId = this.files[0].id;
          for (let i = 0; i < this.files.length; i++) {
            this.goods.resourceIds.push(this.files[i].id);
          }
        }

        if (this.goods.expireDate instanceof Date) {
            console.log('expire');
            console.log(this.goods.expireDate);
            this.goods.expireDate = this.goods.expireDate.toISOString()
            .split('.')[0] + 'Z';
        }
        if(this.isNew) {
          this.saveGoods(this.goods)
            .then((result) => {
              this.showToast(this.$t('toast.item-added'));
              this.$router.push({
                name: 'goods',
                params: { id: result.data.id }
              });
            })
            .catch((error) => {
              this.showToast(error.response.data.message, 'error');
            });
        } else {
          this.editGoods(this.goods)
            .then((result) => {
              this.showToast(this.$t('toast.item-changed'));
              this.$router.push({
                name: 'goods',
                params: { id: result.data.id }
              });
            })
            .catch((error) => {
              this.showToast(error.response.data.message, 'error');
            });
        }
      },
      createResourceUrl(id) {
        if (id) {
          return appSettings.baseUrl + 'api/client/resource/' + id;
        } else {
          return null;
        }
      },
      findAndDeleteBadPhoto() {
        for (let i = this.files.length - 1; i >= 0; i--) {
          if (this.files[i].id === 'del') {
            this.files.splice(i, 1);
          }
        }
      },
      editMapper() {
        const item = this.$route.params.goods;
        item.resourceIds.forEach(item => {
          this.files.push({id: item})
        })
        this.goods.id = item.id;
        this.goods.geo.latitude = item.geo.latitude;
        this.goods.geo.longitude = item.geo.longitude;
        this.selected.category = item.category.id;
        this.goods.category = item.category.id;
        this.goods.title = item.title;
        this.goods.description = item.description;
        this.goods.expireDate = item.expireDate;
      },
      onMapClicked() {
        eventBus.$emit('onChooseLocation', this.goods.geo)
      }
    },
  };
</script>

<style scoped>
  .hollow {
    filter: brightness(0) invert(1);
  }
</style>
